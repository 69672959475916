<template>
<v-container   style="margin-top:60px;margin-bottom:160px;" >
    <v-container v-if="!linked||1">


      
      <v-row class="ma-2 pa-1" >
      <v-col cols="12" class=" pa-1"
        >
        <btnsqr      
          img="img/ico_cert_arch.png"
         
          @click.native="$router.push({name:'Cert_arch'})"
         >Архів сертифікатів
        </btnsqr></v-col>
    </v-row>
      <v-row align="center" justify="center" no-gutters style="    margin-top: 10px;    margin-bottom: 15px;">
    <v-col   cols="12" >
      <v-card style="width: 100%;border-radius: 20px; min-height: 250px; padding-left: 15px;display: flex;flex-direction: column; padding-bottom: 25px;;" class="transparent_to_background">
        <span style="margin-top: 10px;" >
          <font style="color:#f07300">Крок 1:</font> Оберіть сертифікат. Можливі номінали вказані на сертифікатах. 
        </span>
        <span style="margin-top: 10px;" >
          <font style="color:#f07300">Крок 2:</font> Натисність “Замовити”.
        </span>

        <span style="margin-top: 10px;" >
          <font style="color:#f07300">Крок 3:</font> Отриманий сертифікат покажіть на касі у будь-якому вигляді: зображенням у iTeam, скріншотом або роздруківкою.
        </span>

        <span style="margin-top: 10px;" >
          
        </span>
        <v-row>
          <v-col style="margin-top: 36px;font-family: 'Montserrat';font-style: normal;font-weight: 400;font-size: 126px;line-height: 24px;color: #F07300;"  cols="1">!</v-col>
          <v-col cols="11">Слідкуйте за терміном дії сертифікату! Ми не зможемо подовжити його дію або повернути бали.</v-col>
        </v-row>
        <v-row>
          <v-col style="margin-top: 36px;font-family: 'Montserrat';font-style: normal;font-weight: 400;font-size: 126px;line-height: 24px;color: #F07300;" cols="1">!</v-col>
          <v-col cols="11">Статус використання  наразі відобажено лише для "Сільпо" та "Єва" через специфіку даних, наданих нашими партнерами.</v-col>
        </v-row>
    </v-card>
    </v-col>
  </v-row>

  
<v-row>

  <v-col cols="6">
  <v-img  @click="$router.push({ name: 'Withdraw_epi' })"  src="/img/ncert_1.png" alt="Slide Image" style="max-width: 180px;"></v-img>
</v-col>
<v-col cols="6">
  <v-img  @click="$router.push({ name: 'Withdraw_novus' })"  src="/img/ncert_2.png"  alt="Slide Image" style="max-width: 180px;"></v-img>
</v-col>
</v-row>

<v-row>

<v-col cols="6">
  <v-img   @click="$router.push({ name: 'Withdraw_silpo' })" src="/img/ncert_silpo.png" alt="Slide Image" style="max-width: 180px;"></v-img>
</v-col>
<v-col cols="6">
<v-img  @click="$router.push({ name: 'Withdraw_fishka' })"  src="/img/ncert_3.png" alt="Slide Image" style="max-width: 180px;"></v-img>
</v-col>
</v-row>
<v-row>

<v-col cols="6">
  <v-img  @click="$router.push({ name: 'Withdraw_mob' })"   src="/img/ncert_4.png"  alt="Slide Image" style="max-width: 180px;"></v-img>
</v-col>
<v-col cols="6">
<v-img  src="/img/ncert_5.png" @click="$router.push({ name: 'Withdraw_eva' })"   alt="Slide Image" style="max-width: 180px;"></v-img>
</v-col>
</v-row>



<v-row>
<v-col cols="6">
  <v-img  @click="$router.push({ name: 'Withdraw_rozetka' })"   src="/img/ncert_6.png" alt="Slide Image" style="max-width: 180px;"></v-img>
</v-col>
<v-col cols="6">
<v-img  @click="$router.push({ name: 'Withdraw_wog' })"  src="/img/ncert_7.png" alt="Slide Image" style="max-width: 180px;"></v-img>
</v-col>
</v-row>


<v-row>
<v-col cols="6">
  <v-img   src="/img/ncert_8.png" @click="$router.push({ name: 'Withdraw_atb' })"  alt="Slide Image" style="max-width: 180px;"></v-img>
</v-col>

</v-row>

 <!-- <v-card v-if="this.allowed['atb']==1" elevation="10" @click.native="$router.push({ name: 'Withdraw_atb' })" class="rounded-card" style="margin-bottom: 17px;">
       
           
       <v-img src="img/new_emo_atb.png" max-width="100%" contain center></v-img>
     
 </v-card>
 
 <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_epi' })" class="rounded-card" style="margin-bottom: 17px;">
       
           
       <v-img src="img/new_emo_epi.png" max-width="100%" contain center></v-img>
      
 </v-card>
 <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_novus' })" class="rounded-card" style="margin-bottom: 17px;">
       
           
       <v-img src="img/new_emo_novus.png" max-width="100%" contain center></v-img>
      
 </v-card>
 <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_fishka' })" class="rounded-card" style="margin-bottom: 17px;">
       
           
       <v-img src="img/new_emo1.png" max-width="100%" contain center></v-img>
      
 </v-card>
      <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_silpo' })" class="rounded-card" style="margin-bottom: 17px;">
      
            <v-img src="img/new_emo2.png" max-width="100%" contain center></v-img>
           
      </v-card>
      <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_mob' })" class="rounded-card" style="margin-bottom: 17px;">
    
           
            <v-img src="img/new_emo3.png" max-width="100%" contain center></v-img>
           
      </v-card>
      <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_eva' })" class="rounded-card" style="margin-bottom: 17px;">
        
           
            <v-img src="img/new_emo4.png" max-width="100%" contain center></v-img>
           
      </v-card>
      <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_rozetka' })" class="rounded-card" style="margin-bottom: 17px;">
          
           
            <v-img src="img/new_emo5.png" max-width="100%" contain center></v-img>
           
      </v-card>

      <v-card elevation="10" @click.native="$router.push({ name: 'Withdraw_wog' })" class="rounded-card" style="margin-bottom: 17px;">
          
           
            <v-img src="img/new_emo6.png" max-width="100%" contain center></v-img>
           
      </v-card> -->




       
          <v-overlay :value="isLoading">
      <v-progress-circular indeterminate
        color="red">
        
      </v-progress-circular>
      <br />
          </v-overlay>
    </v-container>
</v-container>
</template>

<script>
import axios from "axios";
import { API_LOCATION } from "../../config";
import Btnsqr from "../components/btnsqr.vue";
import Btnrnd from "../components/btnrnd.vue";
export default {
  name: "Info",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Btnsqr,Btnrnd
  },
  data: () => ({
    sms_e:false,
      date: new Date().toISOString().substr(0, 10),
    linked:true,
    
    amount:null,
    personal_number: null,
    oppa_account:null,
    transaction_id:"",
    sms_verify_code:"",
    modal: false,
    menu2: false,
    isLoading:false,
    allowed:[],
    month_lang:{
        'ge':['იანვარი','თებერვალი','მარტი','აპრილი','მაისი','ივნისი','ივლისი','აგვისტო','სექტემბერი','ოქტომბერი','ნოემბერი','დეკემბერი'],
        'ru':['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь']
      },
    
  }),
  computed: {
    task1: function () {
      return this.cards.filter((i) => i.type == "1");
    },
    task2: function () {
      return this.cards.filter((i) => i.type == "2");
    },
    task3: function () {
      return this.cards.filter((i) => i.type == "3");
    },
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    authStatus: function () {
      return this.$store.getters.authStatus;
    },
    page_title: function () {
      return this.$store.getters.page_title;
    },
    fname: function () {
      return this.$store.getters.fname;
    },
    lname: function () {
      return this.$store.getters.lname;
    },
    points: function () {
      return this.$store.getters.points;
    },
    avatar: function () {
      return this.$store.getters.avatar;
    }, 
    title() {
        return this.$t('title')
      },

    date_locale(){
        if(this.$i18n.locale=='ru')
        {
            return 'ru-RU';
        }
        else
        {
            return 'ka-GE';
        }
    }
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      }
  },
  mounted() {},
  created() {
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");
    this.get_allowed_cert();
  },
  methods:{
    get_allowed_cert: function () {
     
     this.isLoading=true;
     axios
     .post(
       API_LOCATION,
       {
         
         action: "get_allowed_cert" 
         
       },
       { headers: { "content-type": "application/x-www-form-urlencoded" } }
     )
     .then((resp) => {
     
       console.log('ALLOWED:'+resp.data); 
       this.allowed=resp.data;
        this.isLoading=false;
       
     });
     
   },
  }
};
</script>

<i18n>
{
  
  "ua":{
      "title": "Обмін балів"
      


      

  }
}
</i18n>

<style scoped>
.rounded-card{
    border-radius:25px;
}
</style>